<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>

    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ pcaText }}</div>

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">{{ combineName }}：</span>
      <div flex-row-flex-start-center gap10>
        <template v-for="(item,index) in combineAnswer">
          <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(item)" alt="" :preview-src-list="[normalizeImageUrl(item)]"></el-image>
        </template>
      </div>
    </div>

    <!--    <c-pre>{{ combineAnswer }}</c-pre>-->
    <!--    <c-pre>{{ computedWidgetCustomContent }}</c-pre>-->
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPCACombineUploadImg',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      pcaText      : '',
      combineName  : '居住证明',
      combineAnswer: [],
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {},
  methods : {},
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    // console.log('【ZtCountrySingle:79】',`this.widget = `,this.widget);
    if (Reflect.has(this.widget, 'answer') && this.widget) {
      let pcaValue                                 = JSON.parse(this.widget.answer)
      let { provinceCode, cityCode, districtCode } = pcaValue
      let pcaText                                  = this.getProvinceAndCityAndDistrict([provinceCode, cityCode, districtCode])
      this.pcaText                                 = pcaText
      ///
      // this.combineName                             = this.widget.combineComponent.combineName
      let combineAnswer = this.widget.combineComponent.combineAnswer
      if (combineAnswer) {
        this.combineAnswer = combineAnswer.split(',')
        // this.combineAnswer = [combineAnswer, combineAnswer]
      }
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss"> @import "component.scss";
@import "component.scss";
</style>
